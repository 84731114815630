import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/dashboard/sales/index')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/account/register'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },
      {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },

    //Brokerage route
    {
        path: '/brokerage/office/grid',
        name: 'brokerage-office-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/office/grid')
    },

    {
        path: '/brokerage/office/list',
        name: 'brokerage-office-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/office/list')
    },
    {
        path: '/brokerage/office/edit',
        name: 'brokerage-office-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/office/office_edit')
    },



    {
        path: '/brokerage/team/grid',
        name: 'brokerage-team-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/team/grid')
    },

    {
        path: '/brokerage/team/edit',
        name: 'brokerage-team-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/team/team_edit')
    },

    {
        path: '/brokerage/staff/grid',
        name: 'brokerage-staff-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/staff/grid')
    },
    {
        path: '/brokerage/staff/edit',
        name: 'brokerage-staff-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/staff/staff_edit')
    },
     
    {
        path: '/brokerage/team',
        name: 'brokerage-team',
        meta: {
            authRequired: true,
        }, 
        component: () => import('../views/pages/company_pages/brokerage/office/grid')
    },
    {
        path: '/brokerage/agent',
        name: 'brokerage-agent',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/agent/list')
    },
    {
        path: '/brokerage/agent/edit',
        name: 'brokerage-agent-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_edit')
    },
    {
        path: '/brokerage/agent/view',
        name: 'brokerage-agent-view',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_view')
    },
   
    {
        path: '/brokerage/agent/termination',
        name: 'brokerage-agent-termination',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_termination')
    },
   
    {
        path: '/brokerage/documents/docs',
        name: 'brokerage-docs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/documents/docs')
    },


    {
        path: '/brokerage/signature/online_sign',
        name: 'brokerage-signature-online-sign',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/signature/doc_online_sign')
    },


    {
        path: '/listing/pending_list',
        name: 'listing-pending',
        meta: {
            authRequired: true,
        }, 
        component: () => import('../views/pages/company_pages/listing/pending_list')
    },
    {
        path: '/listing/active_list',
        name: 'listing-active',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/active_list')
    },
    {
        path: '/listing/exclusive_list',
        name: 'listing-exclusive',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/exclusive_list')
    },

    {
        path: '/listing/overview',
        name: 'listing-overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/overview')
    },


    ///////////////////////Deal START
    {
        path: '/deal/pending_deal',
        name: 'deal-pending-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/pending_deal')
    },

    {
        path: '/deal/sold_deal',
        name: 'deal-sold',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/sold_deal')
    },

    {
        path: '/deal/collapsed_deal',
        name: 'deal-collapsed',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/collapsed_deal')
    },

    {
        path: '/deal/my_handled_deal',
        name: 'deal-my-handled',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/my_handled_deal')
    },


    {
        path: '/deal/deal_overview',
        name: 'deal-overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/overview')
    },
   
    {
        path: '/deal/setup_new_deal',
        name: 'deal-setup',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/setup_new_deal')
    },

    {
        path: '/deal/deal_edit',
        name: 'deal-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/edit_deal')
    },
   
     ///////////////////////Deal FINISH


    ///////////////////////PM ---START
    {
        path: '/pm/property_list',
        name: 'pm-property-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/property_list')
    },

    {
        path: '/pm/setup_new_pm',
        name: 'pm-setup-new-pm',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/setup_new_pm')
    },

    {
        path: '/pm/lanlord_list',
        name: 'pm-landlord-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/landlord_list')
    },

    {
        path: '/pm/supplier_list',
        name: 'pm-supplier-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/supplier_list')
    },
    {
        path: '/pm/overview',
        name: 'pm-overview',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/overview')
    },
    {
        path: '/pm/batch_rent_deposit_cheque',
        name: 'pm-rent-deposit-cheque',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_cheque')
    },

    {
        path: '/pm/batch_rent_deposit_etransfer',
        name: 'pm-rent-deposit-etransfer',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_etransfer')
    },

    {
        path: '/pm/batch_rent_deposit_pad',
        name: 'pm-rent-deposit-pad',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_pad')
    },

    {
        path: '/pm/batch_expense',
        name: 'pm-batch-expense',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_expense')
    },

  
   
    {
        path: '/pm/add_landlord_property',
        name: 'pm-add-landlord-property',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/add_landlord_property')
    },
    {
        path: '/pm/add_landlord',
        name: 'pm-add-landlord',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/add_landlord')
    },
   
    {
        path: '/pm/property_batch_pm_ap',
        name: 'pm-property-batch-pm-ap',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_pm_ap')
    },

    {
        path: '/pm/property_ap_payment',
        name: 'pm-property-ap-payment',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/rta_payment')
    },

    {
        path: '/pm/property_statement_prepare',
        name: 'pm-property-statement-prepare',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/statement_prepare')
    },

    {
        path: '/pm/property_rta_termination',
        name: 'pm-property-rta-termination',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/property_termination')
    },

    {
        path: '/pm/pma_list',
        name: 'pm-pma-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/pma_list')
    },

    {
        path: '/pm/agent_pm_termination',
        name: 'pm-agent-pm-termination',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/agent_pm_termination')
    },

   
    
     ///////////////////////PM ----FINISH

    ///////////////////////AR ----START

    {
        path: '/ar/general',
        name: 'ar-general',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/general')
    },

    {
        path: '/ar/agent_bill',
        name: 'ar-agent-bill',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/agent_bills')
    },
    {
        path: '/ar/agent_bill_detail',
        name: 'ar-agent-bill-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/agent_bill_detail')
    },
    {
        path: '/ar/charge_agent_fee',
        name: 'ar-charge-agent-fee',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/charge_agent_fee')
    },

    {
        path: '/ar/batch_charge_agents',
        name: 'ar-batch-charge-agents',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/batch_charge_agent')
    },

    {
        path: '/ar/deposit_cheque',
        name: 'ar-deposit-cheque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/deposit_cheque')
    },

    {
        path: '/ar/pad_list',
        name: 'ar-pad-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/pad_list')
    },

    {
        path: '/ap/pad_ompletion',
        name: 'pad-completion',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/pad_completion')
    },


    
     ///////////////////////AR ----FINISH


    ///////////////////////AP ----START
    {
        path: '/ap/deal_trust',
        name: 'ap-deal-trust',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/deal_trust')
    },
    {
        path: '/ap/pm_payment_list',
        name: 'ap-pm-payment-list',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/ap/pm_payment_list')
    },
    {
        path: '/ap/create_expense',
        name: 'ap-create-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_expense')
    },
    {
        path: '/ap/edit_expense',
        name: 'ap-edit-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/edit_expense')
    },
  

    {
        path: '/ap/create_rebgv_expense',
        name: 'ap-create-rebgv-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_rebgv_expense')
    },

    {
        path: '/ap/create_western_expense',
        name: 'ap-create-western-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_western_expense')
    },

    

    {
        path: '/ap/eft_list',
        name: 'ap-eft-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/eft_list')
    },
    {
        path: '/ap/eft_ompletion',
        name: 'eft-completion',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/eft_completion')
    },

    
    {
        path: '/ap/trust',
        name: 'ap-trust',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/trust')
    },

    {
        path: '/ap/commission',
        name: 'ap-commission',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/commission')
    },

    {
        path: '/ap/general',
        name: 'ap-general',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/general')
    },
    {
        path: '/ap/advance',
        name: 'ap-advance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/advance')
    },
    {
        path: '/ap/agent_credit',
        name: 'ap-agent-credit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/agent_credit')
    },

    {
        path: '/ap/create_recoverable_expense',
        name: 'ap-create-recoverable-expense',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_recoverable_expense')
    },
    
    {
        path: '/ap/tax_payment',
        name: 'ap-tax-payment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/tax_payment')
    },
     ///////////////////////AP ----FINISH





    ///////////////////////Bank & Cash ----START
     {
        path: '/bt/records',
        name: 'bt-records',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/transfer_records')
    },

    {
        path: '/br/records',
        name: 'br-records',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/reconciliation_records')
    },

    {
        path: '/br/review',
        name: 'br-review',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/reconciliation_review')
    },

    {
        path: '/br/transactions',
        name: 'br-transactions',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/transactions')
    },
     ///////////////////////Bank  & Cash ----FINISH
     



    //////////////////////Training ----START
    {
        path: '/events/training',
        name: 'events-training',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/events/training/index')
    },
    {
        path: '/events/training_edit',
        name: 'events-training-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/events/training/training_edit')
    },
     ///////////////////////Training ----FINISH


    //////////////////////Activity ----START
    {
        path: '/events/activity',
        name: 'events-activity',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/events/activity/index')
    },
    {
        path: '/events/activity_edit',
        name: 'events-activity-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/events/activity/activity_edit')
    },
     ///////////////////////Activity ----FINISH



      //////////////////////House ----START
    {
        path: '/house/list',
        name: 'house-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/houses/list')
    },
    {
        path: '/house/house_edit',
        name: 'house-house-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/houses/house_edit')
    },

     ///////////////////////House ----FINISH

     


    ///////////////////////ACCOUNTING ----START
    {
        path: '/accounting/gl_entry',
        name: 'accounting-gl-entry',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/gl_entry_list')
    },

    {
        path: '/accounting/gl_ref_query',
        name: 'accounting-gl-ref-query',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/gl_ref_query')
    },

    {
        path: '/accounting/financial_statement',
        name: 'accounting-financial-statement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/financial_statement')
    },

    {
        path: '/accounting/gl',
        name: 'accounting-gl',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/fs_gl_list')
    },

    {
        path: '/accounting/fs',
        name: 'accounting-fs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/fs_line_list')
    },

    {
        path: '/accounting/tax/agent_gst',
        name: 'accounting-tax-agent-gst',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/agent_tax')
    },
    
    {
        path: '/accounting/tax/agent_t4a',
        name: 'accounting-tax-agent-t4a',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/agent_t4a')
    },

    {
        path: '/accounting/tax/nr4_list',
        name: 'accounting-tax-nr4',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/nr4_list')
    },

    {
        path: '/accounting/positive_pay',
        name: 'accounting-positive-pay',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/positive_pay')
    },

    
    

    ///////////////////////ACCOUNTING ----FINISH


    ///////////////////////Setting ----START
    {
        path: '/setting/brokerage',
        name: 'setting-brokerage',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/brokerage')
    },

    {
        path: '/setting/bank_account',
        name: 'setting-bank_account-listing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/bank_account_list')
    },



    {
        path: '/setting/cheque',
        name: 'setting-cheque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/cheque_list')
    },


    {
        path: '/setting/printer',
        name: 'setting-printer',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/printer_list')
    },



    
     ///////////////////////Setting ----FINISH
     

    {
        path: '/file-manager',
        name: 'file-manager',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/file-manager')
    },

    {
        path: '/email/inbox',
        name: 'email-inbox',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/read/:id',
        name: 'email-read',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/email/read-email')
    },
    {
        path: '/contacts/grid',
        name: 'contact-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contacts/grid')
    },
    {
        path: '/contacts/list',
        name: 'contact-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contacts/list')
    },
    {
        path: '/user/settings',
        name: 'user-settings',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contacts/user-settings')
    },
    {
        path: '/kanban-board',
        name: 'kanban-board',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/kanban/index')
    },
    {
        path: '/apps/chat',
        name: 'chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/projects/grid',
        name: 'project-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/projects/grid')
    },
    {
        path: '/projects/list',
        name: 'list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/projects/list')
    },
    {
        path: '/projects/overview',
        name: 'overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/projects/overview')
    },
    {
        path: '/projects/create',
        name: 'create',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/projects/create')
    },
    {
        path: '/invoices/list',
        name: 'invoices-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/invoices/list')
    },
    {
        path: '/invoices/detail',
        name: 'invoices-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/invoices/detail')
    },
    {
        path: '/timeline/center',
        name: 'timeline-center',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/timeline/center')
    },
    {
        path: '/timeline/horizontal',
        name: 'timeline-horizontal',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/timeline/horizontal')
    },
    {
        path: '/timeline/left',
        name: 'timeline-left',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/timeline/left')
    },
    {
        path: '/gallery',
        name: 'gallery',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/gallery/index')
    },
    {
        path: '/pricing/table',
        name: 'pricing-table',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/pricing/table')
    },
    {
        path: '/pricing/basic',
        name: 'pricing-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/pricing/basic')
    },
    {
        path: '/ui/alerts',
        name: 'alerts',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/alerts')
    },
    {
        path: '/ui/buttons',
        name: 'buttons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/buttons')
    },
    {
        path: '/ui/cards',
        name: 'cards',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/cards')
    },
    {
        path: '/ui/carousel',
        name: 'carousel',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/carousel')
    },
    {
        path: '/ui/dropdowns',
        name: 'dropdowns',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/dropdowns')
    },
    {
        path: '/ui/grid',
        name: 'ui-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/grid')
    },
    {
        path: '/ui/images',
        name: 'images',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/images')
    },
    {
        path: '/ui/modals',
        name: 'modals',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/modals')
    },
    {
        path: '/ui/progressbars',
        name: 'progressbars',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/progressbar')
    },
    {
        path: '/ui/tabs-accordions',
        name: 'tabs-accordions',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/tabs')
    },
    {
        path: '/ui/typography',
        name: 'typography',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/typography')
    },
    {
        path: '/ui/video',
        name: 'video',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/video')
    },
    {
        path: '/ui/colors',
        name: 'colors',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/colors')
    },
    {
        path: '/ui/general',
        name: 'general',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/general')
    },
    {
        path: '/ui/placeholder',
        name: 'placeholder',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/placeholder')
    },
    {
        path: '/ui/utilities',
        name: 'utility',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/ui/utilities')
    },
    {
        path: '/pages/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/comingsoon',
        name: 'comingsoon',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/utility/comingsoon')
    },
    {
        path: '/pages/faqs',
        name: 'faqs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/utility/faqs')
    },
    {
        path: '/pages/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/utility/profile')
    },
    {
        path: '/pages/starter',
        name: 'starter',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/utility/starter')
    },
    {
        path: '/extended/lightbox',
        name: 'lightbox',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extended/lightbox')
    },
    {
        path: '/extended/rangeslider',
        name: 'rangeslider',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extended/rangeslider')
    },
    {
        path: '/extended/sweet-alert',
        name: 'sweet-alert',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extended/sweet-alert')
    },
    {
        path: '/extended/notifications',
        name: 'notifications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extended/notifications')
    },
    {
        path: '/extended/rating',
        name: 'rating',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extended/rating')
    },
    {
        path: '/widgets',
        name: 'widgets',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/widgets/index')
    },
    {
        path: '/form/elements',
        name: 'elements',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/elements')
    },
    {
        path: '/form/validation',
        name: 'validation',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/validation')
    },
    {
        path: '/form/advanced',
        name: 'advanced',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/advanced')
    },
    {
        path: '/form/editors',
        name: 'editors',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/editors')
    },
    {
        path: '/form/uploads',
        name: 'uploads',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/uploads')
    },
    {
        path: '/form/wizard',
        name: 'wizard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/wizard')
    },
    {
        path: '/form/mask',
        name: 'mask',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forms/mask')
    },
    {
        path: '/tables/basic',
        name: 'table-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tables/basic')
    },
    {
        path: '/tables/advanced',
        name: 'table-advanced',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tables/advanced')
    },
    {
        path: '/charts/line',
        name: 'line',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/line/index')
    },
    {
        path: '/charts/area',
        name: 'area-chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/area/index')
    },
    {
        path: '/charts/bar',
        name: 'bar-chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/bar/index')
    },
    {
        path: '/charts/column',
        name: 'column-chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/column/index')
    },
    {
        path: '/charts/timeline',
        name: 'timeline-chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/timeline/index')
    },
    {
        path: '/charts/mixed',
        name: 'mixed-chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/mixed/index')
    },
    {
        path: '/charts/candlestick',
        name: 'candlestick',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/candlestick/index')
    },
    {
        path: '/charts/boxplot',
        name: 'boxplot',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/boxplot/index')
    },
    {
        path: '/charts/bubble',
        name: 'bubble',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/bubble/index')
    },
    {
        path: '/charts/scatter',
        name: 'scatter',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/scatter/index')
    },
    {
        path: '/charts/heatmap',
        name: 'heatmap',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/heatmap/index')
    },
    {
        path: '/charts/treemap',
        name: 'treemap',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/treemap/index')
    },
    {
        path: '/charts/pie',
        name: 'pie',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/pie/index')
    },
    {
        path: '/charts/radialbar',
        name: 'radialbar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/radiabar/index')
    },
    {
        path: '/charts/radar',
        name: 'radar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/radar/index')
    },
    {
        path: '/charts/polararea',
        name: 'polararea',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts/polararea/index')
    },
    {
        path: '/icons/unicons',
        name: 'unicons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/unicons')
    },
    {
        path: '/icons/feather',
        name: 'feather',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/feather')
    },
    {
        path: '/icons/boxicons',
        name: 'boxicons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/boxicons')
    },
    {
        path: '/icons/material',
        name: 'material',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/material')
    },
    {
        path: '/icons/fontawesome',
        name: 'fontawesome',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/fontawesome')
    },
    {
        path: '/auth/signin-basic',
        name: 'signin-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signin/basic')
    },
    {
        path: '/auth/signin-cover',
        name: 'signin-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signin/cover')
    },
    {
        path: '/auth/signup-cover',
        name: 'signup-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signup/cover')
    },
    {
        path: '/auth/signup-basic',
        name: 'signup-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signup/basic')
    },
    {
        path: '/auth/signout-basic',
        name: 'signout-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signout/basic')
    },
    {
        path: '/auth/signout-cover',
        name: 'signout-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/signout/cover')
    },
    {
        path: '/auth/lockscreen-cover',
        name: 'lockscreen-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/lockscreen/cover')
    },
    {
        path: '/auth/lockscreen-basic',
        name: 'lockscreen-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/lockscreen/basic')
    },
    {
        path: '/auth/forgotpassword-basic',
        name: 'forgotpassword-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/forgotpassword/basic')
    },
    {
        path: '/auth/forgotpassword-cover',
        name: 'forgotpassword-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/forgotpassword/cover')
    },
    {
        path: '/auth/resetpassword-cover',
        name: 'resetpassword-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/resetpassword/cover')
    },
    {
        path: '/auth/resetpassword-basic',
        name: 'resetpassword-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/resetpassword/basic')
    },
    {
        path: '/auth/emailverification-basic',
        name: 'emailverification-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/emailverification/basic')
    },
    {
        path: '/auth/emailverification-cover',
        name: 'emailverification-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/emailverification/cover')
    },
    {
        path: '/auth/2stepverification-cover',
        name: '2stepverification-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/2stepverification/cover')
    },
    {
        path: '/auth/2stepverification-basic',
        name: '2stepverification-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/2stepverification/basic')
    },
    {
        path: '/auth/thankyou-basic',
        name: 'thankyou-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/thankyou/basic')
    },
    {
        path: '/auth/thankyou-cover',
        name: 'thankyou-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/auth/thankyou/cover')
    },
    {
        path: '/maps/google',
        name: 'google-map',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/maps/google/index')
    },
    {
        path: '/maps/vector',
        name: 'vector-map',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/maps/vector/index')
    },
    {
        path: '/maps/leaflet',
        name: 'leaflet-map',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/maps/leaflet/index')
    },
    {
        path: '/error/404-basic',
        name: 'error-404-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/errors/404-basic')
    },
    {
        path: '/error/404-cover',
        name: 'error-404-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/errors/404-cover')
    },
    {
        path: '/error/500-basic',
        name: 'error-500-basic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/errors/500-basic')
    },
    {
        path: '/error/500-cover',
        name: 'error-500-cover',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/errors/500-cover')
    }
]   
